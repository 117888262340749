const types = JSON.parse(localStorage.getItem('typesAvailables'))

const output = [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  }
];

if (types) {
  if (types.solars) {
    output.push({
      title: "solars",
      route: "solars",
      icon: "LayersIcon",
    })
  }
  if (types.installations) {
    output.push({
      title: "installations",
      route: "installations",
      icon: "CpuIcon",
    })
  }
  if (types.assets) {
    output.push({
      title: "assets",
      route: "assets",
      icon: "DatabaseIcon",
    })
  }
  if (types.epis) {
    output.push({
      title: "epis",
      route: "epis",
      icon: "ServerIcon",
    })
  }

}
const empresaChildrens = [
  {
    title: "Usuarios",
    route: "users",
  }
];
if (types) {
  if (types.installations || types.solars ) {
    empresaChildrens.push({
      title: "Clientes",
      route: "subclients",
    })
  }
}
output.push({
      title: "Tareas",
      route: "tasks",
      icon: "FileTextIcon",
    },
    {
      title: "Actuaciones",
      route: "maintenance",
      icon: "ListIcon",
    },
    {
      title: "Etiquetas",
      icon: "TagIcon",
      children: [
        {
          title: "Etiquetas",
          route: "tags",
        },
        {
          title: "Series",
          route: "series",
        },
      ],
    },
    {
      title: "Gestión",
      icon: "CheckSquareIcon",
      children: [
        {
          title: "Eventos",
          route: "events",
        },
        {
          title: "Formularios",
          route: "forms",
        },
        /*{
          title: "Proyectos",
          route: "projects",
        },*/
      ],
    },

    {
      title: "GestionProductos",
      icon: "ShoppingCartIcon",
      children: [
        {
          title: "Productos",
          route: "products",
        },
        {
          title: "Categorías",
          route: "categories",
        },
      ],
    },
    {
      title: "Empresa",
      icon: "BriefcaseIcon",
      children: empresaChildrens,
    });

export default output
