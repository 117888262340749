const types = JSON.parse(localStorage.getItem('typesAvailables'))
const output = [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  }
];

if (types) {
  if (types.solars) {
    output.push({
      title: "solars",
      route: "solars",
      icon: "LayersIcon",
    })
  }
  if (types.installations) {
    output.push({
      title: "installations",
      route: "installations",
      icon: "CpuIcon",
    })
  }
  if (types.assets) {
    output.push({
      title: "assets",
      route: "assets",
      icon: "DatabaseIcon",
    })
  }
  if (types.epis) {
    output.push({
      title: "epis",
      route: "epis",
      icon: "ServerIcon",
    })
  }
}

const empresaChildrens = [
  {
    title: "Usuarios",
    route: "users",
  },
  {
    title: "Departamentos",
    route: "departments",
  },
  {
    title: "Productos",
    route: "products",
  },
  {
    title: "Categorías",
    route: "categories",
  },
];
if (types) {
  if (types.installations ||  types.solars) {
    empresaChildrens.push({
      title: "Clientes",
      route: "subclients",
    })
  }
}

output.push(
    {
      title: "Tareas",
      route: "tasks",
      icon: "ClipboardIcon",
    },
    {
      title: "Actuaciones",
      route: "maintenance",
      icon: "CheckSquareIcon",
    },
    {
      title: "Etiquetas",
      icon: "TagIcon",
      children: [
        {
          title: "Etiquetas",
          route: "tags",
        },
        {
          title: "Series",
          route: "series",
        },
      ],
    },
    {
      title: "Gestión",
      icon: "SettingsIcon",
      children: empresaChildrens,
    },
);

export default output
